import { contarCasasDecimais } from './contarCasasDecimais';
import { formatarQtdIpv } from './formatarQtdIpv';

/* eslint-disable no-console */
export const calcSubTotalProduto = (
  qtdItem: string,
  valUnit: string,
  valDesc: number,
  isEtiqueta: boolean,
  flgIpv?: boolean,
): number => {
  const qtdItemString = (qtdItem || '').toString();
  let qtdItens = parseFloat(qtdItemString.replace(',', '.')) || 0;

  if (flgIpv && !Number.isNaN(qtdItens) && !isEtiqueta) {
    const decimalPlaces = contarCasasDecimais(qtdItemString.slice(0, 7));
    const num = parseFloat(formatarQtdIpv('0,000', qtdItemString.slice(0, 6)));

    if (num < 1) {
      qtdItens = parseFloat(qtdItens.toFixed(3).slice(0, 6)) * 10;
      if (decimalPlaces === 2)
        qtdItens = parseFloat(qtdItens.toFixed(3).slice(0, 6)) / 100;
    } else if (num > 1) {
      if (decimalPlaces === 4)
        qtdItens = parseFloat(qtdItens.toFixed(3).slice(0, 6)) * 10;
      if (decimalPlaces === 2)
        qtdItens = parseFloat(qtdItens.toFixed(3).slice(0, 6)) / 10;
    }
  }

  const valUnitarioString = (valUnit || '').toString();

  let valUnitario: number;

  if (valUnitarioString.length >= 8) {
    valUnitario =
      parseFloat(valUnitarioString.replace(/[^\d,]/g, '').replace(',', '.')) ||
      0;
  } else valUnitario = parseFloat(valUnitarioString.replace(',', '.')) || 0;

  const valBruto = qtdItens * valUnitario;
  const val = Number.isNaN(valBruto) ? 0 : valBruto;

  const valDesconto = (valDesc / 100) * val;
  const valTotal = val - valDesconto;

  return valTotal;
};
